import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import { parseSpanStrikethrough } from '/src/functions/parse/spanStrikethrough'
import stickerGraphic from '/src/dummy-data/images/stickerGraphic'
import affiliateImage from '/src/dummy-data/images/affiliateImage'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Label = loadable(() => import('/src/components/Basic/Label'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const ProductComparison = ({
    backgroundColour,
    stickerGraphic,
    titleArea,
    itemsRepeater,
    addSmallprint,
    smallprint,
    utm
}) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-product-comparison'}>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <LargeCard padding={'no-horizontal'} className={'c-product-comparison__item-container'}>
                        {itemsRepeater.map((node, index) => {
                            return (
                                <div key={index} className="c-product-comparison__item">
                                    {node.addLabel && (
                                        <Label
                                            colour={node.labelColour}
                                            content={node.labelText}
                                            className={'c-product-comparison__item-label'}
                                        />
                                    )}

                                    {node.image && (
                                        <Image data={node.image} className={'c-product-comparison__item-image'} />
                                    )}

                                    {node.title && <div className="c-product-comparison__item-title">{node.title}</div>}

                                    {node.subTitleOne && (
                                        <div className="c-product-comparison__item-subtitle-one">
                                            {parse(node.subTitleOne, parseSpanStrikethrough(node.strikethroughColour))}
                                        </div>
                                    )}

                                    {node.subTitleTwo && (
                                        <div className="c-product-comparison__item-subtitle-two">
                                            {node.subTitleTwo}
                                        </div>
                                    )}

                                    {node.description && (
                                        <div className="c-product-comparison__item-description">
                                            {parse(node.description)}
                                        </div>
                                    )}

                                    <ButtonLink
                                        data={node.callToAction}
                                        size={'lg'}
                                        icon={'arrow'}
                                        utm={utm}
                                        style={
                                            node.changeButtonColour
                                                ? {
                                                      backgroundColor: node.buttonColour,
                                                      color: node.buttonTextColour
                                                  }
                                                : undefined
                                        }
                                    />
                                </div>
                            )
                        })}

                        {stickerGraphic && <Image data={stickerGraphic} className={'c-product-comparison__sticker'} />}
                    </LargeCard>

                    {addSmallprint && <ContentDisclaimer content={smallprint} />}
                </div>
            </div>
        </Section>
    )
}

ProductComparison.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    stickerGraphic: PropTypes.object,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            addLabel: PropTypes.bool,
            labelText: PropTypes.string,
            labelColour: PropTypes.string,
            image: PropTypes.object,
            title: PropTypes.string,
            subTitleOne: PropTypes.string,
            strikethroughColour: PropTypes.string,
            subTitleTwo: PropTypes.string,
            description: PropTypes.string,
            callToAction: PropTypes.shape({
                url: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                target: PropTypes.string
            }),
            changeButtonColour: PropTypes.bool,
            buttonColour: PropTypes.string,
            buttonTextColour: PropTypes.string
        })
    ).isRequired,
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ProductComparison.defaultProps = {
    backgroundColour: 'gradient',
    stickerGraphic: stickerGraphic,
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    itemsRepeater: [
        {
            addLabel: false,
            image: affiliateImage,
            title: 'Your Price £81',
            subTitleOne: 'Was <span>90</span>',
            strikethroughColour: '#ffcc00',
            subTitleTwo: 'Save 10% today',
            description: `<p>Get an exclusive discount/p>`,
            callToAction: {
                url: '#',
                title: 'Individual'
            },
            changeButtonColour: false
        },
        {
            addLabel: true,
            image: affiliateImage,
            title: 'Your Price £126',
            subTitleOne: 'Was <span>140</span>',
            strikethroughColour: '#ffcc00',
            subTitleTwo: 'Save 10% today',
            description: `<p>Get an exclusive discount</p>`,
            callToAction: {
                url: '#',
                title: 'Couple'
            },
            changeButtonColour: true,
            buttonColour: '#ffcc00',
            buttonTextColour: '#000000'
        }
    ],
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>'
}

export default ProductComparison

export const query = graphql`
    fragment ProductComparisonForPageBuilder on WpPage_Acfpagebuilder_Layouts_ProductComparison {
        fieldGroupName
        backgroundColour
        stickerGraphic {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 150)
                }
            }
            altText
            title
        }
        addTitleArea
        title
        description
        itemsRepeater {
            addLabel
            labelText
            labelColour
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 120)
                    }
                }
                altText
                title
            }
            title
            subTitleOne
            strikethroughColour
            subTitleTwo
            description
            callToAction {
                target
                title
                url
            }
            changeButtonColour
            buttonColour
            buttonTextColour
        }
        addSmallprint
        smallprint
    }
`
