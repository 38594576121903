import * as React from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'

export const parseSpanStrikethrough = (highlightColour) => {
    const spec = {
        replace: (domNode) => {
            if (domNode.name === 'span') {
                return (
                    <span style={{ borderColor: highlightColour }}>
                        {domToReact(domNode.children)}
                    </span>
                )
            }
        }
    }

    return spec
}

parseSpanStrikethrough.propTypes = {
    /**
     * Specify a colour to apply to a span tag
     */
    highlightColour: PropTypes.string
}
