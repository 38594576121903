const stickerGraphic = {
    altText: 'Alt text',
    localFile: {
        childImageSharp: {
            gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                    fallback:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAACpklEQVQ4y42UTU9TQRSG37n9SD8slkKlrVBKsaIUjVGMQUEjitEoXyoJICqKBmKQEIPgxoUulC5YmehKozFh4W9wLX+AjW507VINi9LOa+44xeHaIjeZ3DvnzjzznjPnHGDz4zK+zwF4BWAVwA8AvwB8AfAOwCAAt15nocIj9LsLwAoA/mfYB/WV2b9pMmtsKABYB1AEIPUoalvBWJdDBTdN2LoG0IBJh61ggJec7nc6lNEBMIdpLxrrh02VK4YytckSkEKAlgUKDbPnYjPYFPANwA4b1mP8kFsoKzdkKOiiJTaE3IFODaXO6xWsrfbIgN9iSzrAfc1BXjxdy71NASYTPh7Ohjh0oY6Pppp4fTDOZ3N75JnjESXGEorxEfr66fdZxfnJlLxxOc7bQwm+X2pjZ3uYrxezbM0Emc0E+fxhhnN3GxXs3lgDcwsZvsllpS1ChQRYgxD4aU9S9X65OJ+RMzeTfPl0Pz+8OMiWpgBHemOsq/UyEvbw8XSaAz27uDCVYkPcpw7pOhqWO0NuM6aqApShuyMir/XHmU762d0RYeNuHwM+i263YDTi4aHWkIIHAy47NAp6/mSNtENj7xd/3MbnUgqkk355pK2KPZ01HO6Nsf1AFccGVKw4OVrPkb4YH0w0cn4yxbMnIpwZT3JqtF5e6o5KnQV5G/hWx3D91tUEn8w2y/ErCY72xRiLennqWLVyO5MKMB71Kpvtvt9n2Wpl0O+yv0up8wm60NVN1YQ9MhL2bDdlzLjl9duuNtU1VstUyUYyq+QW/8K0rajn3wFES5XSr6+9IP4u2E7pSUPdNBwFnTNUFsoAnAcUDdhypZ645Og4BQek1GXyhm3ZaH+iXHO0u8bXLdykEbP7lRqs0/0ggAldm2sGJK9TY9a8ABP2G89SbCoEBfh+AAAAAElFTkSuQmCC'
                },
                backgroundColor: 'transparent',
                images: {
                    fallback: {
                        src: '/static/57f0d098d361aa5d441355b6e433f15a/cfd20/10-Bubble%402x.png',
                        srcSet: '/static/57f0d098d361aa5d441355b6e433f15a/223a3/10-Bubble%402x.png 576w,\n/static/57f0d098d361aa5d441355b6e433f15a/f80d5/10-Bubble%402x.png 768w,\n/static/57f0d098d361aa5d441355b6e433f15a/cfd20/10-Bubble%402x.png 784w',
                        sizes: '(min-width: 784px) 784px, 100vw'
                    },
                    sources: [
                        {
                            srcSet: '/static/57f0d098d361aa5d441355b6e433f15a/9b296/10-Bubble%402x.avif 576w,\n/static/57f0d098d361aa5d441355b6e433f15a/bcdde/10-Bubble%402x.avif 768w,\n/static/57f0d098d361aa5d441355b6e433f15a/bf97a/10-Bubble%402x.avif 784w',
                            type: 'image/avif',
                            sizes: '(min-width: 800px) 800px, 100vw'
                        },
                        {
                            srcSet: '/static/57f0d098d361aa5d441355b6e433f15a/beb29/10-Bubble%402x.webp 576w,\n/static/57f0d098d361aa5d441355b6e433f15a/2460c/10-Bubble%402x.webp 768w,\n/static/57f0d098d361aa5d441355b6e433f15a/de10f/10-Bubble%402x.webp 784w',
                            type: 'image/webp',
                            sizes: '(min-width: 800px) 800px, 100vw'
                        }
                    ]
                },
                width: 784,
                height: 784
            }
        }
    }
}

export default stickerGraphic
