const affiliateImage = {
    altText: 'Alt text',
    localFile: {
        childImageSharp: {
            gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                    fallback:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEGUlEQVQ4y02UfUwbdRjHnx6bUNtCZ+HGsnXS4SgTB8jeZINuShyxXakMca4KA4br79rCqLpg3FjqEuNMFrNp4h+C+0Mzoy7RiokaTZjGjfmCOt0iKr0rL4UpKsaocHd9eczvejd7ye+Pe+6ez+/7vIIscEDP9dFmkAWSk3knkIj5l8sCVycLXL/Ik8HFCd/Q0oQvLPHcVoknio/EE0bzpwcxDJmXmD/7Iyvx3DGJJ9cknqDIE0xP+RHngojxAFKbxJP3ZYHbSi+WeKLLhlIl2bB9Ek/mZYHDZIxDWeASOBPAubEO/GK4VR6/4MXkpJ/aFbAscCGqVvPH2RCAzGthcp30p9SUH8UowYWr3YrjB6+6Z7z327tK15ptd9daXZEh5zxVLPEkrUKJqjSHwjWppSJPFtPTfrz+dSeeCTteOROu3/Zs//YTrMV478eve4A+A71bYJ+7LDT1eTviTEASM0B6tqgcBlTJb9DbkpMcvnjC8SkigrthHWU48436lazF2FhcaCyrLC+CXbXWB8dHvIizwaQYJQlV5Wdi1Af/jHcpCmuofJwOyHNjndjbWXXs5JPbFUVFtxiazPn6TSZD3s7iIlP1fo8d/O2Vj/9xtZvmMqWGnc5WSYFP01sSMW7p3599+ISv5jym+xUgJvpzzCZ9nfHm3PI7K9hCANj4/PH6SGpSyWFShaVUYIsGPE8NS1GSwLme9CdvNeOmjSt7AWAZDb3VtR7M+fr6fGPeLtZiLDPocw8Nn3V9RwsjZqAasFkDjqh5SIo09JkA8hfbcPis66dzL+x+d1v1qlMAYAdggLUY3QCw/Kng5tD8lS7aEXIW0KMBIyowQT+IUZJOT/lT+GcIv3yvFUvXmvfeUVYIZpPew1oMlXsabND9UEW3cKmNNros/p/DnRrwMbVRZa1hFyd8KYwHcfC5e+ZppVexJofJkFfS6Lg1l6Zhv8feFxtt14AUtigL3GoNyMoC94umUhm3KFGA7ww6ZwHARgtk0OdCyRozCwAFB1o2HJ28rPSiqALHMN6rTJ3W2M1Z45TIAAM0ZKqwWCk5MLBj82oTAJT3dFS9/Pv3B5XOUH0G1GWRo8yhuj04DSpGSRLjwcT4hYflDbdZauzrLLQghSVrzJW0KM8cuesSzgYxPR2g/bjw67ddVsTTcPHtvcyNNSQJCtQpC9w3manxK/McGXI+8OFrTeBqsBmujXgZALB+dK4p/fePhzA22obxrzqOng7XwY0ns764rI3RR20OiSfHEzFu7K8fHj2Avx2Gy5GWZdW3s2CzFhw5fLDqiu+RioGm3TY/AHgBwM0wOrtOpzMoQJpMOot0uFPTPYpN5AlgPAC40HeT5z4PrC9ZASsKDDqG0Tlp2G++1AinBnZkssvoSgFgj04Htf8BaW0P3GU2MhgAAAAASUVORK5CYII='
                },
                backgroundColor: 'transparent',
                images: {
                    fallback: {
                        src: '/static/1682a625655b0b4597c8c60ab93262e3/a2289/Individual-image%402x%402x.png',
                        srcSet: '/static/1682a625655b0b4597c8c60ab93262e3/898c9/Individual-image%402x%402x.png 576w,\n/static/1682a625655b0b4597c8c60ab93262e3/a2289/Individual-image%402x%402x.png 617w',
                        sizes: '(min-width: 617px) 617px, 100vw'
                    },
                    sources: [
                        {
                            srcSet: '/static/1682a625655b0b4597c8c60ab93262e3/f0c51/Individual-image%402x%402x.avif 576w,\n/static/1682a625655b0b4597c8c60ab93262e3/08bf5/Individual-image%402x%402x.avif 617w',
                            type: 'image/avif',
                            sizes: '(min-width: 617px) 617px, 100vw'
                        },
                        {
                            srcSet: '/static/1682a625655b0b4597c8c60ab93262e3/f9360/Individual-image%402x%402x.webp 576w,\n/static/1682a625655b0b4597c8c60ab93262e3/9d51a/Individual-image%402x%402x.webp 617w',
                            type: 'image/webp',
                            sizes: '(min-width: 617px) 617px, 100vw'
                        }
                    ]
                },
                width: 150,
                height: 139
            }
        }
    }
}

export default affiliateImage
